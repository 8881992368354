var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "passholder-exclusion-dates-wrapper stack" },
      [
        _c("style", { tag: "component", attrs: { type: "text/css" } }, [
          _vm._v(
            "\n            .restrictions-grid {\n                display: grid;\n                grid-template-rows: " +
              _vm._s(_vm.gridRows) +
              ";\n            }\n        "
          )
        ]),
        _vm._v(" "),
        _vm.showHeadlineAndIntroText
          ? _c("div", { staticClass: "u-text-center u-margin-bottom-xlarge" }, [
              _vm.headline.length > 0
                ? _c("h1", [_vm._v(_vm._s(_vm.headline))])
                : _vm._e(),
              _vm._v(" "),
              _vm.introText.length > 0
                ? _c("p", [_vm._v(_vm._s(_vm.introText))])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "restriction-filters" },
          _vm._l(_vm.passTypes, function(passType) {
            return _c("div", { staticClass: "restriction-filter" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPassTypes,
                    expression: "selectedPassTypes"
                  }
                ],
                attrs: { type: "checkbox", id: "passType-" + passType.Id },
                domProps: {
                  value: passType.Id,
                  checked: Array.isArray(_vm.selectedPassTypes)
                    ? _vm._i(_vm.selectedPassTypes, passType.Id) > -1
                    : _vm.selectedPassTypes
                },
                on: {
                  click: _vm.setFilters,
                  change: function($event) {
                    var $$a = _vm.selectedPassTypes,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = passType.Id,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedPassTypes = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedPassTypes = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedPassTypes = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "restrictions-checkbox-filter",
                  attrs: { for: "passType-" + passType.Id }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "passTypeIcon",
                      style: "background-color:" + passType.Color
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 18 15",
                            width: "18",
                            height: "15",
                            "aria-hidden": "true",
                            focusable: "false"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "#000",
                              d:
                                "M16 0l1.43 1.4L4.98 14.13 0 9.037l1.43-1.398 3.55 3.63z"
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(passType.Name))])
                ]
              )
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("datepicker", {
          ref: "datepicker",
          staticClass: "full-width",
          attrs: {
            inline: true,
            "full-month-name": true,
            "monday-first": _vm.mondayFirst,
            "disabled-dates": _vm.disabledDates,
            language: _vm.language,
            "day-cell-content": _vm.setCells
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm.showButton
      ? _c("p", { staticClass: "u-text-center u-margin-top-large" }, [
          _c(
            "a",
            {
              staticClass: "button button--primary",
              attrs: { href: _vm.buttonUrl, target: _vm.buttonTarget }
            },
            [_vm._v(_vm._s(_vm.buttonText))]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }