<template>

    <div>
        <div class="passholder-exclusion-dates-wrapper stack">

            <component :is="'style'" type="text/css">
                .restrictions-grid {
                    display: grid;
                    grid-template-rows: {{ gridRows }};
                }
            </component>

            <div class="u-text-center u-margin-bottom-xlarge" v-if="showHeadlineAndIntroText">
                <h1 v-if="headline.length > 0">{{ headline }}</h1>
                <p v-if="introText.length > 0">{{ introText }}</p>
            </div>

            <div class="restriction-filters">
                <div v-for="passType in passTypes" class="restriction-filter">
                    <input type="checkbox" :value="passType.Id" v-on:click="setFilters" v-model="selectedPassTypes" v-bind:id="'passType-' + passType.Id">
                    <label v-bind:for="'passType-' + passType.Id" class="restrictions-checkbox-filter">
                        <span class="passTypeIcon" v-bind:style="'background-color:' + passType.Color">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 15" width="18" height="15" aria-hidden="true" focusable="false"><path fill="#000" d="M16 0l1.43 1.4L4.98 14.13 0 9.037l1.43-1.398 3.55 3.63z"></path></svg>
                        </span>
                        <span>{{ passType.Name }}</span>
                    </label>
                </div>
            </div>

            <!--DEBUG INFO DELETE WHEN TASK IS COMPLETED-->
            <!--<p v-show="selectedPassTypes.length !== 0">
        Selected filters: <span v-for="passType in selectedPassTypes" class="label">{{ passType }}</span>
    </p>-->

            <datepicker ref="datepicker" class="full-width"
                        v-bind:inline="true"
                        v-bind:full-month-name="true"
                        v-bind:monday-first="mondayFirst"
                        :disabled-dates="disabledDates"
                        :language="language" :day-cell-content="setCells">
            </datepicker>



        </div>
        <p class="u-text-center u-margin-top-large" v-if="showButton">
            <a :href="buttonUrl" :target="buttonTarget" class="button button--primary">{{ buttonText }}</a>
        </p>



    </div>
</template>

<script>
    import Vue from 'vue';
    import axios from "axios";
    import VueAxios from "vue-axios";
    import _startsWith from "lodash/startsWith";
    import Datepicker from "vuejs-datepicker";
    import * as allLang from "vuejs-datepicker/dist/locale";


    Vue.use(VueAxios, axios);

    const siteConfig = {};
    Object.assign(siteConfig, window.merlinConfig);
    let language = siteConfig.i18n.locale;
    if (language === 'pt')
        language = 'ptBR';

    var lang = allLang[language];
    if (lang === undefined)
        lang = allLang['en'];




    export default {
        props: {
            passes: {
                type: String,
                default: '',
                required: false
            },
            selectedPass: {
                type: String,
                default: '',
                required: false
            },
            useInvertedDates: {
                type: Boolean,
                default: false,
                required: true
            },
            showHeadlines: {
                type: Boolean,
                default: true,
                required: false
            }
        },
        components: {
            Datepicker
        },
        data() {
            return {
                selectedDate: null,
                mondayFirst: true,
                language: lang,
                days: {
                    short: lang._days,
                    long: []
                },
                months: lang._months,
                passTypes: [],
                selectedPassTypes: [],
                disabledDates: {
                    to: new Date("2019-11-07T00:00:00+01:00"),
                    from: new Date("2020-02-04T00:00:00")
                },
                colors: [],
                noInformationText: '',
                buttonUrl: '',
                buttonText: '',
                buttonTarget: '',
                EntryRestrictionDatesElements: [],
                headline: '',
                introText: '',
                passUids: '',
                useInvertedDatesBool: false //it is possible to invert dates
            }
        },

        created() {
            this.useInvertedDatesBool = this.$props.useInvertedDates;
            this.passUids = this.$props.passes;
            this.initializeFilters();
        },
        //mounted() {
        //    console.log("Mounted");
        //},
        computed: {
            showButton() {
                return this.buttonText !== "" && this.buttonUrl !== "";
            },
            showHeadlineAndIntroText() {
                return this.showHeadlines;
            },
            gridRows() {
                var rows = "";
                for (var i = 0; i < this.passTypes.length; i++) {
                    rows += " [" + this.safeName(this.passTypes[i].Name.trim().toLowerCase()) + "] 1fr";
                }

                return rows;
            }
        },
        methods: {
            initializeFilters() {

                const requestOne = axios.get(`${siteConfig.urlPrefix}/umbraco/api/EntryRestrictionDates/GetPassTypes?passRestrictionIds=${this.passUids}&culture=${siteConfig.i18n.culture}`);
                requestOne.then(response => {
                    
                    this.passTypes = response.data;
                    this.selectedPassTypes = Array.from(this.passTypes, x => x.Id);
                    this.colors = Array.from(this.passTypes, x => x.Color);


                    const urlParams = new URLSearchParams(window.location.search);
                    let selectedPassFromUrl = "";
                    if (urlParams.has('selectedPass')) {
                        selectedPassFromUrl = urlParams.get('selectedPass');
                    }

                    if (this.$props.selectedPass !== "") {
                        this.selectedPassTypes.length = 0;
                        this.selectedPassTypes.push(this.$props.selectedPass);
                    } else if (selectedPassFromUrl !== "") {
                        this.selectedPassTypes.length = 0;
                        this.selectedPassTypes.push(selectedPassFromUrl);
                    }

                    this.initializeCalendar();
                }).catch(error => {
                    console.log(error)
                });
            },
            initializeCalendar() {
                const requestTwo = axios.get(`${siteConfig.urlPrefix}/umbraco/api/EntryRestrictionDates/GetEntryRestrictionDates?passRestrictionIds=${this.passUids}&useInvertedDates=${this.useInvertedDatesBool}&culture=${siteConfig.i18n.culture}`);
                requestTwo.then(response => {
                    if (response.data.FirstDayOfWeekIsSunday) {
                        this.mondayFirst = false;
                    } else {
                        this.mondayFirst = true;
                    }
                    this.days.long = response.data.WeekdayLocalizations;

                    const sDate = new Date(response.data.StartDate);
                    let eDate = new Date(response.data.EndDate);

                    this.disabledDates.to = new Date(sDate.getFullYear(), sDate.getMonth(), sDate.getDate());
                    this.disabledDates.from = new Date(eDate.getFullYear(), eDate.getMonth(), eDate.getDate());

                    this.EntryRestrictionDatesElements = response.data.EntryRestrictionDatesElements;
                    this.headline = response.data.Headline;
                    this.introText = response.data.IntroductionText;

                    this.buttonText = response.data.ButtonTextUnderCalendar;
                    this.buttonUrl = response.data.ButtonLinkUnderCalendar;

                }).catch(error => {
                    console.log(error)
                });
            },
            setCalendarData() {
                if (this.selectedPassTypes.length > 0) {
                    const currentFilters = this.selectedPassTypes.join();

                    const requestTwo = axios.get(`${siteConfig.urlPrefix}/umbraco/api/EntryRestrictionDates/GetEntryRestrictionDates?passRestrictionIds=${currentFilters}&useInvertedDates=${this.useInvertedDatesBool}&culture=${siteConfig.i18n.culture}`);
                    requestTwo.then(response => {
                        this.EntryRestrictionDatesElements = response.data.EntryRestrictionDatesElements;
                    }).catch(error => {
                        console.log(error)
                    });
                }

            },
            setFilters() {
                setTimeout(() => {
                    this.setCalendarData();
                }, 100);
            },
            hexToRgb(hex) {
                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                return result ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                } : null;
            },
            convertHexToRgb(hex) {
                const result = this.hexToRgb(hex);
                if (result !== null) {
                    const arr = [result.r, result.g, result.b, 0.1];
                    return arr.join(',');
                }

                return [0, 0, 0, 0.1];

            },
            getStringDate(date) {
                const year = date.getFullYear();
                const month = `${date.getMonth() + 1}`.padStart(2, 0);
                const day = `${date.getDate()}`.padStart(2, 0);
                const stringDate = [year, month, day].join("");
                return stringDate;
            },
            getRestrictions(key) {
                let retVal = "";
                let index = this.EntryRestrictionDatesElements.findIndex(x => x.Key === key);
                const localPasses = this.EntryRestrictionDatesElements[index].PassTypes; //PassTypes fo the given date

                localPasses.forEach((pass) => {
                    const array1 = this.passTypes;
                    const foundPass = array1.find(element => element.Name === pass);
                    if (foundPass !== undefined && this.selectedPassTypes.includes(foundPass.Id)) {
                        retVal += `<div class="restriction ${this.safeName(foundPass.Name.toLowerCase())}" style="grid-row: ${this.safeName(foundPass.Name.trim().toLowerCase())} ;background-color: rgba(${this.convertHexToRgb(foundPass.Color)});" aria-label="${foundPass.Name}">
                            <span class="restriction__bullet" style="background-color: ${foundPass.Color};"></span>
                            <span class="restriction__text">${foundPass.Name}</span>
                        </div>`
                    }
                });

                return retVal;
            },
            stringToHex(str) {
                let hex = '';
                if (str) {
                    for (let i = 0; i < str.length; i++) {
                        const charCode = str.charCodeAt(i);
                        const hexValue = charCode.toString(16);

                        // Pad with zeros to ensure two-digit representation
                        hex += hexValue.padStart(2, '0');
                    }
                    return hex;
                }
            },
            safeName(str) {
                if (str) {
                    let regex = /[!@#$%^&*(),.?\":{}|<>+]/g;
                    const found = str.match(regex);
                    if (found && Array.isArray(found)) {
                        found.forEach((x) => {
                            str = str.replace(x, this.stringToHex(x));
                        });
                    }
                    return str;
                }
            },
            setCells(cell) {

                const today = new Date(cell.timestamp);
                const stringDate = this.getStringDate(today);
                var startDateIndex = stringDate;
                let currentDayInCell;

                currentDayInCell = this.EntryRestrictionDatesElements
                    .filter(({ Key }) => {
                        let calendarDay = startDateIndex.substring(0, 6); //e.g., "201910"
                        return _startsWith(Key, calendarDay); //e.g., ("20191001", "201910")
                    })
                    .find(current => {
                        //find the specific day in the filtered days
                        return current.Key === startDateIndex;
                    });


                if (currentDayInCell && currentDayInCell.Key === stringDate) {

                    return `<div class="cell-content-wrapper">
                        <div class="date">${today.getDate()}</div>
                        <div class="restrictions restrictions-grid">${this.getRestrictions(currentDayInCell.Key)}</div>
                    </div>`;
                }

                else {
                    return `<div class="cell-content-wrapper">
                            <div class="date">${today.getDate()}</div>
                            <div class="no-information">${this.noInformationText}</div>
                        </div>`;
                }

            }
        }
    };
</script>