import tippy from 'tippy.js';
import { hideAll } from 'tippy.js';
import Vue from 'vue';
import VueAccessibleModal from 'vue-accessible-modal';
import PasstypeRestrictionDates from '../../components/passtype-restriction-dates/PasstypeRestrictionDates.vue';



const pageHasComparisonTable = document.querySelector(".passes-comparison-table-block table thead") != null;

if (pageHasComparisonTable) {

    const initialPadding = "50%"; //Just set it to 50% since images are 600x300pixels which equals to 50% padding

    function throttle(fn, wait) {
        var time = Date.now();
        return function () {
            if (time + wait - Date.now() < 0) {
                fn();
                time = Date.now();
            }
        };
    }

    function checkIfImgShouldCollapse(distanceToTop, distanceToBottom) {

        if (window.matchMedia("(max-height: 1200px)").matches) {

            if (distanceToTop <= distanceToBottom) {
                document.querySelectorAll(".passes-comparison-table-block table .comparable-item__img-and-text__image").forEach((image) => {
                    image.style.paddingBottom = "0px";
                });
            } else if (distanceToTop > distanceToBottom) {
                document.querySelectorAll(".passes-comparison-table-block table .comparable-item__img-and-text__image").forEach((image) => {
                    image.style.paddingBottom = initialPadding;
                });
            }

        }

    }

    function checkIfLegalTextShouldCollapse(distanceToTop, distanceToBottom) {

        if (window.matchMedia("(max-height: 1200px)").matches) {

            if (distanceToTop <= distanceToBottom) {

                document.querySelectorAll(".passes-comparison-table-block table .comparable-item__legal-text").forEach((legalText) => {
                    legalText.style.display = "none";
                });

            } else if (distanceToTop > distanceToBottom) {

                document.querySelectorAll(".passes-comparison-table-block table .comparable-item__legal-text").forEach((legalText) => {
                    legalText.style.display = "flex";
                });

            }
        }

    }

    function checkIfOtherItemsShouldCollapse(distanceToTop, distanceToBottom) {

        if (window.matchMedia("(max-height: 1200px)").matches) {

            if (distanceToTop <= distanceToBottom) {

                document.querySelectorAll(".passes-comparison-table-block table .comparable-item__img-and-text__small-teaser").forEach((teaser) => {
                    teaser.style.display = "none";
                });

                document.querySelectorAll(".passes-comparison-table-block table .comparable-item__price-area").forEach((priceArea) => {
                    priceArea.style.display = "none";
                });

                document.querySelectorAll(".passes-comparison-table-block table .comparable-item__additional-button-area").forEach((buttonArea) => {
                    buttonArea.style.display = "none";
                });

            } else if (distanceToTop > distanceToBottom) {

                document.querySelectorAll(".passes-comparison-table-block table .comparable-item__img-and-text__small-teaser").forEach((teaser) => {
                    teaser.style.display = "flex";
                });

                document.querySelectorAll(".passes-comparison-table-block table .comparable-item__price-area").forEach((priceArea) => {
                    priceArea.style.display = "flex";
                });

                document.querySelectorAll(".passes-comparison-table-block table .comparable-item__additional-button-area").forEach((buttonArea) => {
                    buttonArea.style.display = "block";
                });
            }

        }

    }

    function checkForItems() {
        var tableHead = document.querySelector(".passes-comparison-table-block table thead");
        var distanceToTop = tableHead.getBoundingClientRect().top;
        //console.log("distanceToTop: ", distanceToTop);

        var pageHeader = document.querySelector("header");
        var distanceToBottom = pageHeader.getBoundingClientRect().bottom;
        //console.log("distanceToBottom: ", distanceToBottom);

        checkIfImgShouldCollapse(distanceToTop, distanceToBottom);
        checkIfLegalTextShouldCollapse(distanceToTop, distanceToBottom);
        checkIfOtherItemsShouldCollapse(distanceToTop, distanceToBottom);
    }

    /* Feature detection */
    let passiveIfSupported = false;

    try {
        window.addEventListener("test", null,
            Object.defineProperty(
                {},
                "passive",
                {
                    get: function () { passiveIfSupported = { passive: true }; }
                }
            )
        );
    } catch (err) { }


    window.addEventListener("scroll", throttle(checkForItems, 100), passiveIfSupported);




    Vue.use(VueAccessibleModal, {
        transition: 'fade'
    });


    var modalContentComponent = Vue.component('modal-content-component', {
        props: {
            passes: {
                type: String,
                default: '',
                required: true
            },
            selectedPass: {
                type: String,
                default: '',
                required: false
            },
            useInvertedDates: {
                type: Boolean,
                default: false,
                required: true
            },
            closeModalText: {
                type: String,
                default: 'Close',
                required: true
            }
        },
        components: {
            'passtype-restrictions-dates': PasstypeRestrictionDates
        },
        template: `
            <div>
                <button class="close-modal" type="button" @click="$modal.close()">
                    <span class="visuallyhidden">{{ closeModalText }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true" focusable="false">
                        <path fill="currentColor" d="M23.772 8.228a1 1 0 010 1.414l-6.364 6.364 6.364 6.364a1 1 0 01-1.414 1.414l-6.364-6.364-6.364 6.364a1 1 0 01-1.414-1.414l6.364-6.365-6.364-6.363A1 1 0 019.63 8.228l6.365 6.363 6.363-6.363a1 1 0 011.414 0z"></path>
                    </svg>
                </button>
                <passtype-restrictions-dates v-bind="$props" />
            </div>`
    });

    new Vue({
        el: "#pctb", //PricesComparisonTableBlock
        methods: {
            showModal(e) {
                const closeModalText = e.target.dataset.closeModalText;
                const passes = e.target.dataset.ids;
                const selectedPass = e.target.dataset.selectedId;
                const useInvertedDates = e.target.dataset.useInvertedDates === "true" ? true : false;
                this.$modal.show(modalContentComponent, {
                    props: {
                        passes: passes,
                        selectedPass: selectedPass,
                        useInvertedDates: useInvertedDates,
                        closeModalText: closeModalText
                    },
                    //listeners: { submit: this.submitHandler },
                    //classes: ['foo', 'bar', 'v-modal'],
                    label: 'Modal',
                    attributes: {
                        id: 'modal',
                        'data-attribute': 'foo'
                    }
                });
            }
        }
    });



    tippy(document.querySelectorAll('[rel="tooltip"]'), {
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true,
        hideOnClick: false
    });

    window.addEventListener("scroll", hideAll({ duration: 0 }), passiveIfSupported);

}